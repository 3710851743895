<template>
  <v-container fluid class="hero-container">
    <!-- Hero -->
    <v-row>
      <v-col cols="12">
        <div class="check-out-app-now mt-16 mb-3 ml-11" v-html="$t('home.hero_title')"></div>
        <div class="your-access-to ml-13" v-html="$t('home.hero_subtitle')"></div>
        <v-btn
          elevation="2"
          raised
          rounded
          x-large
          class="watch-now-button white--text px-10 mt-6 ml-13"
          @click="signUp"
        >{{ $t('home.hero_join') }}</v-btn>
        <v-btn
          elevation="2"
          raised
          rounded
          x-large
          class="watch-now-button white--text px-10 mt-3 ml-13"
          @click="login"
        >{{ $t('home.hero_login') }}</v-btn>
      </v-col>
    </v-row>

    <!-- Sample -->
    <v-row>
      <v-col cols="12" class="px-0">
        <v-img
          alt="Phone"
          class="shrink"
          contain
          :src="demoImage"
          transition="scale-transition"
        />
      </v-col>
    </v-row>

    <!-- Features -->
    <v-row>
      <v-col cols="12">
        <p class="features px-6">{{ $t('home.features.title') }}</p>
        <v-container fluid mb-6>
          <v-row v-for="(feature, index) in features" :key="index">
            <v-col cols="3" align="center">
              <v-img
                :alt="feature.title"
                class="shrink pt-5"
                contain
                :src="feature.icon"
                transition="scale-transition"
                width="40"
              />
            </v-col>
            <v-col cols="9" class="pl-0">
              <div class="feature-title mb-1">{{ $t(feature.title) }}</div>
              <div class="feature-description">{{ $t(feature.description) }}</div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <!-- Footer -->
    <v-row>
      <v-col cols="12" class="pa-0">
        <div class="footer-container py-6 px-6">
          <div class="footer">© 2022 WeLIVE. All rights reserved.</div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      features: [
        {
          icon: require('../assets/hd.png'),
          title: 'home.features.watch_live_in_hd',
          description: 'home.features.watch_live_in_hd_desc',
        },
        {
          icon: require('../assets/stream-selection.png'),
          title: 'home.features.stream_selection',
          description: 'home.features.stream_selection_desc',
        },
        {
          icon: require('../assets/rewards.png'),
          title: 'home.features.rewards',
          description: 'home.features.rewards_desc',
        },
        {
          icon: require('../assets/video-call.png'),
          title: 'home.features.video_call',
          description: 'home.features.video_call_desc',
        },
      ]
    }
  },
  computed: {
    demoImage() {
      if (this.$i18n.locale === 'ja') {
        return require('../assets/phone-ja.png')
      } else {
        return require('../assets/phone.png')
      }
    }
  },
  methods: {
    signUp() {
      this.$router.push({ name: 'signup', query: this.$route.query })
    },
    login() {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style scoped>
.hero-container {
  background-image: url("../assets/background.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: -70px;
  font-family: 'Montserrat';
  font-style: normal;
}
.check-out-app-now {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;

  /* Purple gradient */
  background: linear-gradient(153.4deg, #A496FF -5.9%, #00095A 83.31%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.your-access-to {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}
.watch-now-button {
  background: linear-gradient(153.4deg, #A496FF -5.9%, #00095A 83.31%);
  font-family: 'Lato';
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.572917px;
}
.features {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #4B17C5;
}
.feature-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
}
.feature-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
}
.footer-container {
  height: 72px;
  background: #4B17C5;
}
.footer {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}
</style>